import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService, { doDelete, doGet, doPost } from "../../config/DataService";
import Api from "../../config/Api";

////////////////////////////// For Logout USer /////////////////////
export const logoutAdvertiserPublisher = createAsyncThunk(
  "admin/logoutAdvertiserPublisher",
  async () => {
    try {
      const response = await doGet(Api.LOGOUT_ADVERTISER_);
      return response?.data;
    } catch (error) {}
  }
);


export const loginAdmin = createAsyncThunk("admin/loginAdmin", async (data) => {
  try {
    const response = await doPost(Api.ADMIN_LOGIN, data);
    localStorage.setItem('token', JSON.stringify(response?.data?.data?.token));
    localStorage.setItem('role', JSON.stringify(response?.data?.data?.role));
    return response.data;
  } catch (error) {}
});

export const RoleForgotPassword = createAsyncThunk(
  "admin/RoleForgotPassword",
  async (data) => {
    try {
      const response = await doPost(Api.FORGOT_PASSWORD, data);
      return response?.data;
    } catch (error) {}
  }
);

export const verfiyRoleOtp = createAsyncThunk(
  "admin/verfiyRoleOtp",
  async (data) => {
    try {
      const response = await doPost(Api.VERIFY_OTP, data);
      return response.data;
    } catch (error) {}
  }
);

export const resetRolePassword = createAsyncThunk(
  "admin/resetRolePassword",
  async (data) => {
    try {
      const response = await doPost(Api.RESET_PASSWORD, data);
      return response?.data;
    } catch (error) {}
  }
);

export const adminDataList = createAsyncThunk(
  "admin/adminDataList",
  async () => {
    try {
      const response = await doGet(Api.SINGLE_ADMIN_DATA);
      return response?.data;
    } catch (error) {}
  }
);

export const updateAdminData = createAsyncThunk(
  "admin/updateAdminData",
  async (data) => {
    try {
      const response = await doPost(Api.UPDATE_ADMIN_DATA, data);
      return response?.data;
    } catch (error) {}
  }
);
export const changeAdminPassword = createAsyncThunk(
  "admin/changeAdminPassword",
  async (data) => {
    try {
      const response = await doPost(Api.CHANGE_ADMIN_PASSWORD, data);
      return response?.data;
    } catch (error) {}
  }
);

export const addEditCMS = createAsyncThunk(
  "admin/addEditCMS",
  async (data) => {
    try {
      const response = await doPost(Api.ADD_TERMS_AND_CONDITION, data);
      return response?.data;
    } catch (error) {}
  }
);

export const getCMSData = createAsyncThunk(
  "admin/getCMSData",
  async () => {
    try {
      const response = await doGet(Api.GET_CMS_DATA);
      return response?.data;
    } catch (error) {}
  }
);

export const listOfDashboardData = createAsyncThunk(
  "admin/listOfDashboardData",
  async () => {
    try {
      const response = await doGet(Api.ALL_DASHBOARD_DATA);
      return response?.data;
    } catch (error) {}
  }
);
export const getListAllAdvertiserList = createAsyncThunk(
  "admin/getListAllAdvertiserList",
  async () => {
    try {
      const response = await doGet(Api.ADVERTISER_LIST);
      return response?.data;
    } catch (error) {}
  }
);

export const advertiserTransationHistory = createAsyncThunk(
  "advertiser/transationHistory",
  async () => {
    try {
      const response = await doGet(Api.ALL_ADVERTISER_TRANSATION_HISTORY);
      return response?.data;
    } catch (error) {}
  }
);

export const transationHistory = createAsyncThunk(
  "admin/transationHistory",
  async () => {
    try {
      const response = await doGet(Api.ALL_TRANSATION_HISTORY);
      return response?.data;
    } catch (error) {}
  }
);

export const userTransationHistory = createAsyncThunk(
  "admin/userTransationHistory",
  async () => {
    try {
      const response = await doGet(Api.ALL_USER_TRANSATION_HISTORY);
      return response?.data;
    } catch (error) {}
  }
);

export const approveRejectAdvertiser = createAsyncThunk(
  "admin/approveRejectAdvertiser",
  async (data) => {
    try {
      const response = await doPost(Api.APPROVE_REJECT_ADVERTISER, data);
      return response?.data;
    } catch (error) {}
  }
);
export const activeDeactiveAdvertiser = createAsyncThunk(
  "admin/activeDeactiveAdvertiser",
  async (data) => {
    try {
      const response = await doPost(Api.ACTIVE_DEACTIVE_ADVERTISER, data);
      return response?.data;
    } catch (error) {}
  }
);

export const listOfAllUser = createAsyncThunk(
  "admin/listOfAllUser",
  async () => {
    try {
      const response = await doGet(Api.ALL_USER_DATA);
      return response?.data;
    } catch (error) {}
  }
);
export const listOfAdvertiserDepositHistory = createAsyncThunk(
  "admin/listOfAdvertiserDepositHistory",
  async () => {
    try {
      const response = await doGet(Api.ADVERTISER_DEPOSIT_HISTORY);
      return response?.data;
    } catch (error) {}
  }
);

////////////////////////// ADVERTISER SERVICES /////////////////////////////////////

export const updateAdvertiserAmount = createAsyncThunk(
  "admin/updatePublisherAmount",
  async (data) => {
    try {
      const response = await doPost(Api.UPDATE_ADVERTISER_AMOUNT, data);
      return response?.data;
    } catch (error) {}
  }
);

export const loginAdvertiser = createAsyncThunk(
  "admin/loginAdvertiser",
  async (data) => {
    try {
      const response = await doPost(Api.ROLL_LOGIN, data);

      return response.data;
    } catch (error) {}
  }
);

export const RollForgotPassword = createAsyncThunk(
  "admin/RollForgotPassword",
  async (data) => {
    try {
      const response = await doPost(Api.ROLL_FORGOTPASSWORD, data);

      return response?.data;
    } catch (error) {}
  }
);

export const verfiyRollOtp = createAsyncThunk(
  "admin/verfiyRollOtp",
  async (data) => {
    try {
      const response = await doPost(Api.ROLL_VERIFY_OTP, data);
      return response.data;
    } catch (error) {}
  }
);

export const resetRollPassword = createAsyncThunk(
  "admin/resetRollPassword",
  async (data) => {
    try {
      const response = await doPost(Api.ROLL_RESETPASSWORD, data);
      return response?.data;
    } catch (error) {}
  }
);

export const changeAdevrtiserPassword = createAsyncThunk(
  "admin/changeAdevrtiserPassword",
  async (data) => {
    try {
      const response = await doPost(Api.CHANGE_ADVERTISER_PASSWORD, data);
      return response?.data;
    } catch (error) {}
  }
);

export const registerAdvertiserUser = createAsyncThunk(
  "admin/registerAdvertiserUser",
  async (data) => {
    try {
      const response = await doPost(Api.CREATE_ADVERTISER_PUBLISHER, data);
      return response?.data;
    } catch (error) {}
  }
);

export const advertiserDeposit = createAsyncThunk(
  "admin/advertiserDeposit",
  async (data) => {
    try {
      const response = await doPost(Api.ADVERTISER_DEPOSIT, data);
      return response?.data;
    } catch (error) {}
  }
);

export const listOfAdvertiserUser = createAsyncThunk(
  "admin/listOfAdvertiserUser",
  async () => {
    try {
      const response = await doGet(Api.ADVERTISER_LIST);
      return response?.data;
    } catch (error) {}
  }
);
export const listOfDepositHistory = createAsyncThunk(
  "admin/listOfAdvertiserUser",
  async () => {
    try {
      const response = await doGet(Api.DEPOSIT_HISTORY);
      return response?.data;
    } catch (error) {}
  }
);


export const listOfNetwork = createAsyncThunk(
  "admin/listOfNetwork",
  async () => {
    try {
      const response = await doGet(Api.GET_ALL_NETWORKS);                 
      return response?.data;
    } catch (error) {} 
  }
);
export const updateAdvertiserData = createAsyncThunk(
  "admin/updateAdvertiserData",
  async (data) => {
    try {
      const response = await doPost(Api.CREATE_ADVERTISER_PUBLISHER, data);

      if(response != undefined){
        return  response?.data
        
      }
    } catch (error) {
    }
  }
);


export const advertiserDashboardData = createAsyncThunk(
  "advertiserDashboardData/listOfAdvertiserUser",
  async () => {
    try {
      const response = await doGet(Api.ADVERTISER_DASHBOARD);
      return response?.data;
    } catch (error) {}
  }
);

///////////////////// CATEGORY SERVICE ///////////////////
export const listOfAllCategory = createAsyncThunk(
  "admin/listOfAllCategory",
  async () => {
    try {
      const response = await doGet(Api.ALL_CATEGORY_LIST);
      return response?.data;
    } catch (error) {}
  }
);
export const listAllCategory = createAsyncThunk(
  "admin/listAllCategory",
  async () => {
    try {
      const response = await doGet(Api.CATEGORY_LIST);
      return response?.data;
    } catch (error) {}
  }
);


export const singleCategoryData = createAsyncThunk(
  "admin/singleCategoryData",
  async () => {
    try {
      const response = await doGet(Api.SINGLE_CATEGORY);
      return response?.data;
    } catch (error) {}
  }
);
export const deleteCategoryData = createAsyncThunk(
  "admin/deleteCategoryData",
  async (data) => {
    try {
      const response = await doPost(Api.DELETE_CATEGORY_DATA, data);
      return response?.data;
    } catch (error) {}
  }
);
export const createCategoryData = createAsyncThunk(
  "admin/createCategoryData",
  async (data) => {
    try {
      const response = await doPost(Api.CREATE_CATEGORY_DATA, data);
      return response?.data;
    } catch (error) {}
  }
);
export const updateCategoryData = createAsyncThunk(
  "admin/updateCategoryData",
  async (data) => {
    try {
      const response = await doPost(Api.CREATE_CATEGORY_DATA, data);
      return response?.data;
    } catch (error) {}
  }
);

/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////  ADVERTISEMENT SERVICES ////////////////////////////////////////////

export const advertisementListData = createAsyncThunk(
  "admin/advertisementListData",
  async () => {
    try {
      const response = await doGet(Api.ALL_ADVERTISEMENT_LIST);
      return response?.data;
    } catch (error) {}
  }
);
export const createAdvertisementData = createAsyncThunk(
  "admin/createAdvertisementData",
  async (data) => {
    try {
      const response = await doPost(Api.CREATE_ADVERTISEMENT, data);
      return response?.data;
    } catch (error) {}
  }
);
export const deleteAdvertisementData = createAsyncThunk(
  "admin/deleteAdvertisementData",
  async (data) => {
    try {
      const response = await doPost(Api.DELETE_ADVERTISEMENT, data);

      return response?.data;
    } catch (error) {}
  }
);
export const pauseResAdvertisementData = createAsyncThunk(
  "admin/pauseResAdvertisementData",
  async (data) => {
    try {
      const response = await doPost(Api.PAUSE_RESTART_ADVERTISEMENT, data);
      return response?.data;
    } catch (error) {}
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////// PUBLISHER SERVICES ////////////////////////////////////////////////////
export const roleLogin = createAsyncThunk("admin/roleLogin", async (data) => {
  try {
    const response = await doPost(Api.ADVERTISER_PUBLISHER_LOGIN, data);

    return response.data;
  } catch (error) {}
});

export const registerPublisherUser = createAsyncThunk(
  "admin/registerPublisherUser",
  async (data) => {
    try {
      const response = await doPost(Api.CREATE_ADVERTISER_PUBLISHER, data);
      return response?.data;
    } catch (error) {}
  }
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////// ADMIN SERVICES ////////////////////////////////////////////////////

export const adminAdvertisementListData = createAsyncThunk(
  "admin/adminAdvertisementListData",
  async () => {
    try {
      const response = await doGet(Api.ALL_ADVERTISEMENT_LIST + "?" + new Date().getTime());
      return response?.data;
    } catch (error) {}
  }
);


export const approveRejectAdvertisement = createAsyncThunk(
  "admin/approveRejectAdvertisement",
  async (data) => {
    try {
      const response = await doPost(Api.APPROVE_REJECT_ADVERTISEMENT, data);
      return response?.data;
    } catch (error) {}
  }
);
export const activeDeactiveAdvertisement = createAsyncThunk(
  "admin/activeDeactiveAdvertisement",
  async (data) => {
    try {
      const response = await doPost(
        Api.ACTIVE_DEACTIVE_ADMIN_ADVERTISEMENT,
        data
      );
      return response?.data;
    } catch (error) {}
  }
);
export const allWalletAdress = createAsyncThunk(
  "admin/allWalletAdress",
  async ()=>{
    try {
      const response = await doGet(
        Api.GETALLWALLETADRESS
      )
      return response?.data
      
    } catch (error) {
      
    }
  }
)

//////////////////////////PRESALE SERVICES////////////////

export const listOfAllPresale = createAsyncThunk(
  "admin/listOfAllCategory",
  async () => {
    try {
      const response = await doGet(Api.ALL_PRESALE_LIST);
      return response?.data;
    } catch (error) {}
  }
);

export const createPresaleData = createAsyncThunk(
  "admin/createPresaleData",
  async (data) => {
    try {
      const response = await doPost(Api.CREATE_PRESALE_DATA, data);
      return response?.data;
    } catch (error) {}
  }
);
export const updatePresaleData = createAsyncThunk(
  "admin/updatePresaleData",
  async (data) => {
    try {
      const response = await doPost(Api.CREATE_PRESALE_DATA, data);
      return response?.data;
    } catch (error) {}
  }
);

// advertisment get data

export const getAdvertisementData = createAsyncThunk(
  'admin/getAdvertisementData',
  async ()=>{
    try {
      const response = await doGet(Api.GET_ADVERTIMENT_DATA)
      return response?.data
    } catch (error) {
      
    }
  }
)