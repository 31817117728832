import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AdvertApexChat = ({ dashbordData }) => {
  const [chartDetails, setChartDetails] = useState([]);
  const [totalAdvertisementsCount, setTotalAdvertisementsCount] = useState([]);
  useEffect(() => {
    setChartDetails(dashbordData?.activeTotalShownCountByDate);
    setTotalAdvertisementsCount(dashbordData?.totalShownCountByDate)
  }, [dashbordData]);


  const series = [
    {
      name: "Advertisement",
      data: chartDetails // Make sure `dates` is defined or passed as a prop
    },
    {
      name: "Total Advertisements",
      data: totalAdvertisementsCount
    }
  ];

  const [options, setOptions] = useState({
    chart: {
      type: "area",
      stacked: false,
      height: 290,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: "zoom"
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    title: {
      // text: "Stock Price Movement",
      align: "left"
    },
    grid: {
      // borderColor: "#00FF00",
      strokeDashArray: 2,
    },
    stroke: {
      colors: ["#f9cc5d"]
    },
    fill: {
      colors: ["#f9cc5d", "#fe806b", "#4cb58c"],
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val;
        }
      },
      title: {
        text: "Active"
      }
    },
    xaxis: {
      type: "datetime"

    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return val;
        }
      }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={290}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default AdvertApexChat;
