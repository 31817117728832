import React from 'react';

export default function Cms() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 60 60"
    >
      <g>
        <path
          d="M30 12a18 18 0 1 0 18 18 18.021 18.021 0 0 0-18-18ZM20 35a2 2 0 0 0 2-2 1 1 0 0 1 2 0 4 4 0 0 1-8 0v-6a4 4 0 0 1 8 0 1 1 0 0 1-2 0 2 2 0 0 0-4 0v6a2 2 0 0 0 2 2Zm14 1a1 1 0 0 1-2 0v-6.485l-1.063 2.837a1 1 0 0 1-1.874 0L28 29.515V36a1 1 0 0 1-2 0V24a1 1 0 0 1 1.937-.352L30 29.152l2.063-5.5A1 1 0 0 1 34 24Zm10-9a1 1 0 0 1-2 0 2 2 0 1 0-2 2 4 4 0 1 1-4 4 1 1 0 0 1 2 0 2 2 0 1 0 2-2 4 4 0 1 1 4-4Z"
          fill="#ffffff"
          opacity="1"
          data-original="#000000"
          class=""
        />
        <path
          d="M58.855 27.281a1.789 1.789 0 0 0-1.5-1.541l-3.855-.63a23.852 23.852 0 0 0-3.427-8.261l2.275-3.18a1.8 1.8 0 0 0-.018-2.157A23.808 23.808 0 0 0 50.5 9.5a26.954 26.954 0 0 0-2.012-1.83 1.8 1.8 0 0 0-2.157-.018l-3.18 2.275A23.852 23.852 0 0 0 34.89 6.5l-.631-3.851a1.787 1.787 0 0 0-1.54-1.5 25.565 25.565 0 0 0-5.438 0 1.787 1.787 0 0 0-1.54 1.5L25.11 6.5a23.852 23.852 0 0 0-8.261 3.427l-3.18-2.275a1.8 1.8 0 0 0-2.157.018A26.954 26.954 0 0 0 9.5 9.5a23.808 23.808 0 0 0-1.83 2.012 1.8 1.8 0 0 0-.018 2.157l2.275 3.18A23.852 23.852 0 0 0 6.5 25.11l-3.851.63a1.789 1.789 0 0 0-1.5 1.541 25.565 25.565 0 0 0 0 5.438 1.807 1.807 0 0 0 1.5 1.54l3.851.631a23.852 23.852 0 0 0 3.427 8.261l-2.275 3.18a1.8 1.8 0 0 0 .018 2.157A26.954 26.954 0 0 0 9.5 50.5a25.161 25.161 0 0 0 2.011 1.831 1.775 1.775 0 0 0 2.157.018l3.18-2.275A23.852 23.852 0 0 0 25.11 53.5l.631 3.851a1.787 1.787 0 0 0 1.54 1.5 25.565 25.565 0 0 0 5.438 0 1.787 1.787 0 0 0 1.54-1.5l.631-3.851a23.852 23.852 0 0 0 8.261-3.427l3.18 2.275a1.775 1.775 0 0 0 2.157-.018A25.161 25.161 0 0 0 50.5 50.5a26.954 26.954 0 0 0 1.831-2.011 1.8 1.8 0 0 0 .018-2.157l-2.275-3.18A23.852 23.852 0 0 0 53.5 34.89l3.851-.631a1.807 1.807 0 0 0 1.5-1.54 25.565 25.565 0 0 0 0-5.438ZM30 50a20 20 0 1 1 20-20 20.023 20.023 0 0 1-20 20Z"
          fill="#ffffff"
          opacity="1"
          data-original="#000000"
          class=""
        />
      </g>
    </svg>
  );
}
