import React from 'react'

function editpage() {
  return (
    <>
    
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.904" viewBox="0 0 20 19.904" className="admin-sidebar-icons">
  <g id="__TEMP__SVG__" transform="translate(0 -0.096)">
    <g id="Group_13" data-name="Group 13">
      <path id="Path_10" data-name="Path 10" d="M10.232,1.391a.625.625,0,0,0-.465,0L2.307,4.375,10,7.451l7.693-3.076ZM18.75,5.3l-8.125,3.25v9.9L18.75,15.2V5.3ZM9.375,18.452v-9.9L1.25,5.3v9.9l8.125,3.25ZM9.3.23A1.875,1.875,0,0,1,10.7.23l8.911,3.565a.625.625,0,0,1,.392.58V15.2a1.25,1.25,0,0,1-.786,1.16l-8.981,3.593a.625.625,0,0,1-.465,0L.787,16.362A1.25,1.25,0,0,1,0,15.2V4.375A.625.625,0,0,1,.392,3.8Z" fill="#fff"/>
    </g>
  </g>
</svg>

    
    </>
  )
}

export default editpage