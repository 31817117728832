import React from 'react'

function dashboard() {
  return (
    <>

<svg     className="admin-sidebar-icons" id="__TEMP__SVG__" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_9" data-name="Group 9">
    <g id="Group_8" data-name="Group 8">
      <path id="Path_6" data-name="Path 6" d="M0,0H1.25V18.75H20V20H0ZM18.521,14.859a.625.625,0,0,0,.088-.88L12.984,7.1a.625.625,0,0,0-.925-.046L8.825,10.291,4.255,4.008a.625.625,0,0,0-1.01.735l5,6.875a.625.625,0,0,0,.948.075L12.454,8.43l5.187,6.341a.625.625,0,0,0,.88.087Z" fill-rule="evenodd" fill="#fff"/>
    </g>
  </g>
</svg>



    </>
  )
}

export default dashboard