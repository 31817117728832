import React from 'react';

function UpArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 128 128"
      class="uparrow-rotated"
    >
      <g>
        <path
          d="M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z"
          fill="#c7c9fd"
          opacity="1"
          data-original="#000000"
        />
      </g>
    </svg>
  );
}

export default UpArrow;
