import React from 'react'

function usercard() {
  return (
    <>
    
    <svg xmlns="http://www.w3.org/2000/svg"  className="admin-sidebar-icons" width="20" height="15" viewBox="0 0 20 15" fill="#fff">
  <path id="Path_206" data-name="Path 206" d="M10,9.795a1.527,1.527,0,0,1-.361,1.04,1.133,1.133,0,0,1-.889.415h-5a1.133,1.133,0,0,1-.889-.415A1.527,1.527,0,0,1,2.5,9.795a6.937,6.937,0,0,1,.088-1.147,4.625,4.625,0,0,1,.288-1.006,1.716,1.716,0,0,1,.591-.762A1.59,1.59,0,0,1,4.414,6.6q.059.039.293.176t.366.21q.132.073.347.171a2.059,2.059,0,0,0,.42.142,1.936,1.936,0,0,0,.82,0,2.059,2.059,0,0,0,.42-.142q.215-.1.347-.171t.366-.21q.234-.137.293-.176a1.59,1.59,0,0,1,.947.278,1.716,1.716,0,0,1,.591.762,4.625,4.625,0,0,1,.288,1.006A6.937,6.937,0,0,1,10,9.795ZM8.467,4.717a2.136,2.136,0,0,1-.649,1.567,2.136,2.136,0,0,1-1.567.649,2.136,2.136,0,0,1-1.567-.649,2.136,2.136,0,0,1-.649-1.567,2.136,2.136,0,0,1,.649-1.567A2.136,2.136,0,0,1,6.25,2.5a2.136,2.136,0,0,1,1.567.649A2.136,2.136,0,0,1,8.467,4.717ZM17.5,9.063v.625a.3.3,0,0,1-.312.313H11.563a.3.3,0,0,1-.313-.313V9.063a.3.3,0,0,1,.313-.312h5.625a.3.3,0,0,1,.313.313Zm0-2.461v.547a.35.35,0,0,1-.352.352H11.6a.35.35,0,0,1-.352-.352V6.6A.35.35,0,0,1,11.6,6.25h5.547A.35.35,0,0,1,17.5,6.6Zm0-2.539v.625A.3.3,0,0,1,17.188,5H11.563a.3.3,0,0,1-.313-.312V4.062a.3.3,0,0,1,.313-.312h5.625a.3.3,0,0,1,.313.312Zm1.25,9.375V1.563a.317.317,0,0,0-.312-.312H1.563a.317.317,0,0,0-.312.313V13.438a.317.317,0,0,0,.313.313H5v-.938a.3.3,0,0,1,.313-.312h.625a.3.3,0,0,1,.313.312v.938h7.5v-.938a.3.3,0,0,1,.313-.312h.625a.3.3,0,0,1,.312.312v.938h3.438a.317.317,0,0,0,.313-.312ZM20,1.563V13.438A1.567,1.567,0,0,1,18.438,15H1.563a1.5,1.5,0,0,1-1.1-.459A1.5,1.5,0,0,1,0,13.438V1.563A1.5,1.5,0,0,1,.459.459,1.5,1.5,0,0,1,1.563,0H18.438a1.5,1.5,0,0,1,1.1.459A1.5,1.5,0,0,1,20,1.563Z"/>
</svg>

    
    
    </>
  )
}

export default usercard