import React from 'react'

function userlist() {
  return (
    <>

        
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" className="admin-sidebar-icons">
  <g id="__TEMP__SVG__" transform="translate(0 -2.5)">
    <g id="Group_11" data-name="Group 11">
      <g id="Group_10" data-name="Group 10">
        <path id="Path_7" data-name="Path 7" d="M7.5,10A3.75,3.75,0,1,0,3.75,6.25,3.75,3.75,0,0,0,7.5,10ZM10,6.25a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,10,6.25Zm5,10a1.224,1.224,0,0,1-1.25,1.25H1.25A1.224,1.224,0,0,1,0,16.25c0-1.25,1.25-5,7.5-5S15,15,15,16.25Zm-1.25,0a3.34,3.34,0,0,0-1.04-2.08C11.9,13.35,10.361,12.5,7.5,12.5s-4.4.85-5.21,1.665a3.347,3.347,0,0,0-1.04,2.08Z" fill="#fff"/>
        <path id="Path_8" data-name="Path 8" d="M16.875,6.25a.625.625,0,0,1,.625.625V8.75h1.875a.625.625,0,0,1,0,1.25H17.5v1.875a.625.625,0,0,1-1.25,0V10H14.375a.625.625,0,0,1,0-1.25H16.25V6.875A.625.625,0,0,1,16.875,6.25Z" fill="#fff" fill-rule="evenodd" />
      </g>
    </g>
  </g>
</svg>
      
    </>
  )
}

export default userlist