import React from 'react'
import PageIndex from '../../../PageIndex'
import './videoplayer.css'
import Index from '../../../Index'
// import ReactPlayer from 'react-player'

function VideoPlayer() {
    return (
        <>
            <Index.Box className="video-main-contain">
                <Index.Box className="video-container">
                    <Index.Typography component="p" variant='p' className='advertisement-title'>Advertisement</Index.Typography>
                    <Index.Box className="video-wrapper">
                        <video muted autoPlay='true' class="video-main"  >
                            <source src={PageIndex.Video.video} type="video/mp4" alt="banner video" />
                        </video>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default VideoPlayer
