import * as Yup from "yup";
const startSpace = /^(?!\s).*$/;
const isValidFileType = (fileName) => {
  if (!fileName) return false;

  // Define allowed extensions
  const validExtensions = ["jpg", "jpeg", "png"];

  // Extract the extension using a regular expression
  const extension = fileName.split(".").pop().toLowerCase();

  // Check if the extracted extension is in the list of valid extensions
  return validExtensions.includes(extension);
};
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")

    .matches(
      /^(?!\.)([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,})@([a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,})$/,
      "Please enter valid email"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please enter email"),
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password length should be minimum 8 character")
   
    
});

// // Validation EditProfile
const digitsOnly = (value) =>
  /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;
const mobilePattern = (value) =>
  /^([6789]\d{2}\d{4}\d{3})$/.test(value) || value.length === 0;
const textAndSpaceOnly = (value) =>
  /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;

export const EditProfileSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    .required("Please enter name"),

  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .email("Please enter valid email")
    .matches(
      /^(?!\.)([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,})@([a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,})$/,
      "Please enter valid email"
    )
    .required("Please enter email"),
  mobileNumber: Yup.string()
    .required("Please enter mobile number")
    .matches(/^([6789]\d{2}\d{4}\d{3})$/, "Please enter valid mobile number")
    .min(10, "Please enter valid mobile number"),
  adminWallet: Yup.string()
    .max(100, "Amount exceeds limit. Please enter an admin wallet up to 100")
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,4})?|0(\.\d*[1-9]+\d{0,3})?)$/,
      "Please enter a valid admin wallet"
    )
    .test(
      "is-not-zero",
      "Value should be greater than 0",
      (value) => parseFloat(value) !== 0
    )

    .required("Please enter a admin wallet"),
  buyBackWallet: Yup.string()
    .max(
      100,
      "Buy back wallet exceeds limit. Please enter an by back wallet commission up to 100"
    )
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,4})?|0(\.\d*[1-9]+\d{0,3})?)$/,
      "Please enter a valid buy back wallet"
    )
    .test(
      "is-not-zero",
      "Value should be greater than 0",
      (value) => parseFloat(value) !== 0
    )

    .required("Please enter a buy back wallet"),
  file: Yup.mixed()
    .notRequired()

    .test(
      "is-valid-type",
      "Please upload only .jpg, .png, .jpeg files",
      (value) => {
        if (!value) return true;

        // For newly uploaded files, value is a File object
        if (value instanceof File) {
          return isValidFileType(value.name, "image");
        }

        // For editing, value might be a string URL or an object with a name property
        const fileName = typeof value === "string" ? value : value.name;
        return isValidFileType(fileName, "image");
      }
    )
});
export const EditAdvertiserProfileSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    .required("Please enter name"),
  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .email("Please enter valid email")
    .matches(
      /^(?!\.)([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,})@([a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,})$/,
      "Please enter valid email"
    )
    .required("Please enter email"),
  company: Yup.string()
    .required("Please enter company name")
    .min(1, "Company Name is Too Short.")
    .matches(
      /^(?=.*[a-zA-Z])[a-zA-Z0-9@#_-]+$/,
      "Please enter valid company name"
    ),
  file: Yup.mixed()
    .notRequired()

    .test(
      "is-valid-type",
      "Please upload only .jpg, .png, .jpeg files",
      (value) => {
        if (!value) return true;

        // For newly uploaded files, value is a File object
        if (value instanceof File) {
          return isValidFileType(value.name, "image");
        }

        // For editing, value might be a string URL or an object with a name property
        const fileName = typeof value === "string" ? value : value.name;
        return isValidFileType(fileName, "image");
      }
    )
});

export const ChangepasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter old password"),
  newPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter new password")
    .min(8, "Password length should be minimum 8 character")
    .max(25)
    .min(8, "Password length should be minimum 8 character")
    .max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\\|[\]{};:'",.<>/?]).{8,}$/,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter confirm password")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Confirm password must match with new password"
    )
});
export const ChangeAdvertiserpasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter old password"),
  newPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter new password")
    .min(8, "Password length should be minimum 8 character")
    .max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\\|[\]{};:'",.<>/?]).{8,}$/,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter confirm password")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Confirm password must match with new password"
    )
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .matches(
      /^(?!\.)([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,})@([a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,})$/,
      "Please enter valid email"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please enter email")
});

export const verifyOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^\d{4}$/, "OTP must be a 4-digit number")
    .required("Please enter your four digit otp")
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(" Please enter new password")
    .matches(startSpace, "Starting space not allow")
    .min(8, "Password length should be minimum 8 character")
    .max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\\|[\]{};:'",.<>/?]).{8,}$/,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),

  confirmPassword: Yup.string()
    .required(" Please enter confirm password")
    .oneOf(
      [Yup.ref("password")],
      "Confirm password must match with new password"
    )
});
export const registerSchema = Yup.object().shape({
  password: Yup.string()
    .required(" Please enter password")
    .matches(startSpace, "Starting space not allow")
    .min(8, "Password length should be minimum 8 character")
    .max(25)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\\|[\]{};:'",.<>/?]).{8,}$/,
      "Password including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),

  confirmpassword: Yup.string()
    .required("Please enter confirm password")
    .oneOf(
      [Yup.ref("password")],
      "Confirm password must match with new password"
    ),
  name: Yup.string()
    .required("Please enter name")
    .min(2, "Name must be at least 2 characters")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name must have only capital and small letters"
    ),
  company: Yup.string()
    .required("Please enter company name")
    .matches(
      /^(?=.*[a-zA-Z])[a-zA-Z0-9_\s\W]+$/,
      "Please enter valid company name"
    )
    .min(1, "Company Name is Too Short."),
  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\.)([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,})@([a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,})$/,
      "Please enter valid email"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please enter email")
});

export const advertisementSchema = Yup.object().shape({
  title: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Title must have only capital and small letters"
    )
    .required("Please enter title"),
  // category: Yup.array().required("Please select category"),
  category: Yup.array()
    .min(1, "Please select at least one category") // Ensure at least one category is selected
    .required("Please select category"),

  maxSpendLimitPerTransaction: Yup.string()
    .matches(startSpace, "Starting space not allow")

    .matches(
      /^-?(\d+(\.\d{1,2})?|\.\d{1,2})$/,
      "Please enter valid max spend limit per transaction"
    )
    .required("Please enter max spend limit per transaction"),
  duration: Yup.string().when("advertisement_type", {
    is: "video",
    then: () => Yup.string().notRequired(), // If advertisement_type is "video", duration is not required
    otherwise: () =>
      Yup.string()
        .matches(startSpace, "Starting space not allowed")
        .matches(
          /^-?(\d+(\.\d{1,2})?|\.\d{1,2})$/,
          "Please enter a valid duration"
        )
        .test(
          "lengthValidation",
          "Duration must be between 15 or 20 seconds",
          (value) => {
            const durationInSeconds = parseFloat(value);
            return durationInSeconds >= 15 && durationInSeconds <= 20;
          }
        )
        .required("Please enter duration")
  }),

  startDate: Yup.date().required("Please select start date"),
  endDate: Yup.date()
    .required("Please select end date")
    .test(
      "is-greater-than-start",
      "End date must be grater than start date",
      function (endDate) {
        const { startDate } = this.parent; // Access the 'startDate' value
        if (startDate && endDate) {
          return endDate >= startDate;
        }
        return true;
      }
    ),

  description: Yup.mixed().when("advertisement_type", {
    is: "text",
    then: () => Yup.mixed().notRequired(),
    otherwise: () => Yup.mixed().required("Please enter description")
  }),

  hyperLink: Yup.string()
  .notRequired()
    .matches(
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
      // /^(www\.)?[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "Please enter valid redirect link"
    ),

  video: Yup.mixed().when("advertisement_type", {
    is: "video",
    then: () => Yup.mixed().required("Please select video"),
    otherwise: () =>
      Yup.mixed().when("advertisement_type", {
        is: "image",
        then: () => Yup.mixed().required("Please select image"),
        otherwise: () => Yup.mixed().notRequired() // No error message for "text"
      })
  }),
  textdetails: Yup.mixed().when("advertisement_type", {
    is: "text",
    then: () => Yup.mixed().required("Please select text details"),
    otherwise: () => Yup.mixed().notRequired()
  })
});

export const advertiserDepositSchema = Yup.object().shape({
  network: Yup.string().required("Please select network"),
  depositAmount: Yup.number().required("Please enter amount")
});
export const termsConditionSchema = Yup.object().shape({
  description: Yup.string().required("Please enter description")
});

export const updateAdvertiserSchema = Yup.object().shape({
  walletAmount: Yup.string().required("Please enter a wallet")
});

export const categorySchema = Yup.object().shape({
  Category_Type: Yup.string()
    .min(1, "At least one category type is required")
    .required("Please select category type"),

  Category_Name: Yup.string().when("Category_Type", {
    is: "Other",
    then: () =>
      Yup.string()
        .matches(startSpace, "Starting space not allow")
        .matches(
          /^(?!\s)[A-Za-z\s]+$/,
          "Category name must have only capital and small letters"
        )
        .required("Please enter category name"),
    otherwise: () =>
      Yup.string().when("Category_Type", {
        is: "Top",
        then: () => Yup.string().nullable().notRequired(),
        otherwise: () => Yup.mixed().nullable().notRequired() // No error message for "text"
      })
    // otherwise: () => Yup.string().nullable()
  }),

  Category_Number: Yup.string().when("Category_Type", {
    is: "Top",
    then: () =>
      Yup.string()
        .matches(/^\d+$/, "Category number must be an integer")
        .test(
          "is-not-zero",
          "Value should be greater than 0",
          (value) => parseFloat(value) !== 0
        )
        .max(
          200,
          "Category number exceeds limit. Please enter a category number up to 200"
        )
        .required("Please enter category number"),
    otherwise: () => Yup.string().nullable()
  }),

  Category_Image: Yup.mixed()
    .test("size", "Image size is too large", (value) => {
      if (value?.size) {
        return value?.size <= 5 * 1024 * 1024;
      }
      return true;
    })
    .test(
      "fileFormat",
      "Image must be in JPG, JPEG, and PNG format",
      (value) => {
        console.log(value, "value");

        if (value?.type && !undefined) {
          const allowedExtensions = ["image/jpg", "image/jpeg", "image/png"];
          return allowedExtensions.includes(value?.type);
        }
        return true;
      }
    )
    .notRequired()
});

// presale validation

export const presaleSchema = Yup.object().shape({
  startValue: Yup.date().required("Please enter start date"),
  endValue: Yup.string().required("Please enter end date"),
  round_no: Yup.string().required("Please enter round no"),
  minimum_purchase: Yup.string().required("Please enter minimum purchase"),
  monthly_release: Yup.string()
    .max(
      100,
      "Monthly release exceeds limit. Please enter a monthly release up to 100"
    )
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,4})?|0(\.\d*[1-9]+\d{0,3})?)$/,
      "Please enter a valid monthly release"
    )
    .test(
      "is-not-zero",
      "Value should be greater than 0",
      (value) => parseFloat(value) !== 0
    )

    .required("Please enter monthly release"),
  round_percent: Yup.string()
    .max(
      100,
      "Round percent exceeds limit. Please enter a round percent up to 100"
    )
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,4})?|0(\.\d*[1-9]+\d{0,3})?)$/,
      "Please enter a valid round percent"
    )
    .test(
      "is-not-zero",
      "Value should be greater than 0",
      (value) => parseFloat(value) !== 0
    )
    .required("Please enter round percent"),
  token_price: Yup.string().required("Please enter token price")
});
