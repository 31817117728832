import React from 'react'
import Index from '../../Index'

function PrimaryButton(props) {
  return (
    <>
       <Index.Box className='primary-btn-main'>
        <Index.Button className={props.className} onClick={props.onClick}>{props.btnLabel}</Index.Button>
      </Index.Box>
    </>
  )
}

export default PrimaryButton
